import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Layout from "../../../components/layout";
import OrderButton from "../../../components/orderbutton";
import ProductTable from "../../../components/productTable";
import SEO from "../../../components/seo";
import Content from "../../../content/produk/transparent-indoor-led/P3.91/Product-Page.json";
import ProductImage from "../../../images/product/transparent-indoor-led/P3.91/Product-Summary-P3.91-Transparent-Indoor-Led.jpg";
import "../../../styles/product.css";

const P391 = () => {
    return (
        <Layout>
            <SEO
                title={Content.seo.title}
                description={Content.seo.description}
                lang={Content.seo.lang}
            />
            <section id={"produk"} className={"section-bg"}>
                <Container>
                    <div className={"section-title"}>
                        <h1>P3.91 Transparent Indoor LED Display Module</h1>
                        <Row>
                            <Col>
                                <Image
                                    src={ProductImage}
                                    fluid
                                    alt={"Gambar modul"}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col sm={true}>
                                <div className={"section-title"}>
                                    <h2 className={"text-center"}>
                                        Product Summary
                                    </h2>
                                </div>
                                <p style={{ textAlign: "justify" }}>
                                    Transparent LED Indoor P3.91 merupakan layar
                                    LED transparan selayaknya layar LED
                                    konvensional yang dikombinasikan dengan
                                    celah antara LED berbentuk <i>Louver</i>{" "}
                                    yang memberi efek transparan. Layar ini
                                    merupakan layar penuh warna
                                    <i>(full color)</i> dengan kepadatan
                                    pixel&nbsp;
                                    <abbr title={"Pixel per Inch"}>
                                        <i>(PPI)</i>
                                    </abbr>
                                    &nbsp;tinggi yang dipergunakan di dalam
                                    ruangan (indoor) sehingga yang cocok
                                    digunakan pada bidang dinding tirai kaca,
                                    keindahan panggung tari, tampilan komersial,
                                    teknik kota dan Sangat cocok dipergunakan
                                    sebagai media promosi Sebuah Usaha atau
                                    Lembaga dan berbagai keperluan lainnya.
                                    Transparent LED indoor memiliki
                                    karakteristik sebagai berikut:
                                </p>
                                <ul style={{ textAlign: "left" }}>
                                    <li>
                                        Module ringan dan warna cabinet dapat
                                        disesuaikan.
                                    </li>
                                    <li>
                                        Kepadatan pixel yang tinggi sehingga
                                        cocok dipakai untuk skenario dalam
                                        ruangan menghasilkan visual yang detail
                                        dan menarik.
                                    </li>
                                    <li>
                                        Media yang inovatif, interaktif dan
                                        informatif untuk berbagai keperluan
                                        dalam ruang <i>(indoor)</i>.
                                    </li>
                                    <li>
                                        Struktur cabinet yang kuat dan tahan
                                        lama.
                                    </li>
                                </ul>
                                <p>
                                    <OrderButton
                                        messageText={Content.messageText}
                                    />
                                </p>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col>
                                <div className={"section-title"}>
                                    <h2 className={"text-center"}>
                                        LED Cabinet Unit
                                    </h2>
                                </div>
                                <ProductTable
                                    tableName={"LED Unit Cabinet"}
                                    tableData={Content.moduleAndCabinetUnit}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col>
                                <div className={"section-title"}>
                                    <h2 className={"text-center"}>
                                        Contoh Pemasangan P3.91 Transparent
                                        Indoor
                                    </h2>
                                    <Row>
                                        <Col>
                                            <div className="embed-responsive embed-responsive-4by3">
                                                <iframe
                                                    title={"example"}
                                                    className="embed-responsive-item"
                                                    src="https://www.youtube.com/embed/zipTSGirqzY"
                                                />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="embed-responsive embed-responsive-4by3">
                                                <iframe
                                                    className="embed-responsive-item"
                                                    src="https://www.youtube.com/embed/Csd_WGYk9pE"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </Layout>
    );
};

P391.propTypes = {
    siteTitle: PropTypes.string,
};

P391.defaultProps = {
    siteTitle: ``,
};

export default P391;
